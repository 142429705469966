import { DollarCircleOutlined, HomeOutlined } from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { Spin } from "antd";
import { Link } from "gatsby";
import { navigate } from "gatsby-link";
import { get, map } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { updateActiveId } from "../../../redux/actions/product-actions";
import {
  loginSuccessful,
  pairingLogin,
} from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";

import moment from "moment";
import {
  directReward2Img,
  exploreCommunityImg,
  incomeCap2Img,
  leaderReward2Img,
  nodeTeam2Img,
  package2Img,
  placementReward2Img,
  register2Img,
  vipRewardImg,
} from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { mainColor } from "../../../styles/config";
import RewardListing from "../../homepage/pages/RewardListing";
import PairingLoginModal from "../components/PairingLoginModal";

//let displayRobot = [];

// markup
const RewardExplore = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  // const [loading, setLoading] = useState(false);
  const [loginVisible, setLoginVisible] = useState(false);
  const user = get(props, "user.user");

  useEffect(() => {
    // let query = queryString.parse(location.search);
    // console.log("here");
    let user_access = get(props, "user");
    // console.log("user_access", user_access);

    if (get(user_access, "pairingLogin") === false) {
      setLoginVisible(true);
    } else {
      let loginTime = get(user_access, "pairingLoginTime");
      loginTime = moment(new Date(loginTime));
      let expiredTime = loginTime.add(30, "minutes").toDate().getTime();
      let dateNow = new Date();
      // console.log("dateNow", dateNow.getTime());
      // console.log("expiredTime", expiredTime);

      if (dateNow.getTime() > expiredTime) {
        // console.log("exceed");
        setLoginVisible(true);
      } else {
        //refresh
        props.pairingLogin(true);
      }
    }
  }, []);

  const rewards = [
    {
      icon: exploreCommunityImg,
      activeIcon: (
        <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />
      ),
      text: (
        <React.Fragment>
          {/* My Community */}
          {t("myCommunity", sourceKey.pairing)}

          {/* <br></br>我的社区 */}
        </React.Fragment>
      ),
      value: "community",
      path: routes.exploreCommunity.to(),
    },
    {
      icon: register2Img,
      activeIcon: (
        <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
      ),
      text: (
        <React.Fragment>
          {t("register", sourceKey.pairing)}
          {/* <br></br>注冊 */}
        </React.Fragment>
      ),
      value: "register",
      path: routes.register.to(),
    },
    {
      icon: nodeTeam2Img,
      activeIcon: (
        <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
      ),
      text: (
        <React.Fragment>
          {t("nodeTeam", sourceKey.pairing)}
          {/* <br></br>节点团队 */}
        </React.Fragment>
      ),
      value: "communityReward",
      path: routes.nodeTeam.to(),
    },
    {
      icon: package2Img,
      activeIcon: (
        <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
      ),
      text: (
        <React.Fragment>
          {t("packageUpgrade", sourceKey.pairing)}
          {/* <br></br>配套升级 */}
        </React.Fragment>
      ),
      value: "news",
      path: routes.topUp.to(),
    },
    {
      icon: incomeCap2Img,
      activeIcon: (
        <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
      ),
      text: (
        <React.Fragment>
          {t("incomeCapacity", sourceKey.pairing)}
          {/* <br></br>收益容量 */}
        </React.Fragment>
      ),
      value: "incomeCapacity",
      path: routes.incomeCapacitySummary.to(),
    },
  ];

  const rewards2 = [
    {
      icon: directReward2Img,
      activeIcon: (
        <HomeOutlined style={{ color: mainColor, fontSize: "25px" }} />
      ),
      text: (
        <React.Fragment>
          {t("directReward", sourceKey.pairing)}
          {/* <br></br>直推奖 */}
        </React.Fragment>
      ),
      value: "directReward",
      path: routes.directReward.to(),
    },
    {
      icon: placementReward2Img,
      activeIcon: (
        <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
      ),
      text: (
        <React.Fragment>
          {t("placementReward", sourceKey.pairing)}
          {/* <br></br>对碰奖 */}
        </React.Fragment>
      ),
      value: "placementReward",
      path: routes.placementReward.to(),
    },
    {
      icon: leaderReward2Img,
      activeIcon: (
        <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
      ),
      text: (
        <React.Fragment>
          {t("leaderReward", sourceKey.pairing)}
          {/* <br></br>领导 */}
        </React.Fragment>
      ),
      value: "leaderReward",
      path: routes.leaderReward.to(),
    },
    {
      icon: vipRewardImg,
      activeIcon: (
        <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
      ),
      text: (
        <React.Fragment>
          {/* VIP Reward <br></br> */}
          {/* 贵宾 */}
          {t("vipReward", sourceKey.pairing)}
        </React.Fragment>
      ),
      value: "news",
      path: routes.vipReward.to(),
    },
    // {
    //   icon: giftRewardImg,
    //   activeIcon: (
    //     <DollarCircleOutlined style={{ color: mainColor, fontSize: "25px" }} />
    //   ),
    //   text: (
    //     <React.Fragment>
    //       Overall Reward <br></br>总体
    //     </React.Fragment>
    //   ),
    //   value: "news",
    //   path: routes.vipReward.to(),
    // },
  ];

  const types = [
    {
      title: get(user, "username"),
      titleCn: "獎勵",
      actions: rewards,
    },
    {
      title: t("rewards", sourceKey.pairing),
      titleCn: "獎勵2",
      actions: rewards2,
    },
  ];

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={false}>
          <NavHeader
            showBack={true}
            onBack={() => {
              navigate(routes.home.to());
              //props.pairingLogin(false);
            }}
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {/* <span className="inline-block text-base text-white text-right font-semibold"> */}
                  {/* 探索{" "} */}
                  {/* <MediaQuery maxWidth={440}>
                      <br />
                    </MediaQuery> */}
                  {t("community", sourceKey.home)}
                  {/* </span> */}
                </div>
              </React.Fragment>
            }
          >
            <div
            // className="box-shadow-center pb-32"
            // style={{ paddingBottom: "100px" }}
            // style={{height: "105vh"}}
            >
              <div className="">
                {/* <div className="flex justify-center text-lg mb-4 font-bold">
                  {get(user, "username")}
                </div> */}
                <div className="grid grid-cols-4 gap-2 px-6 pt-1">
                  {map(rewards, (action, index) => {
                    let colSpan = "2";

                    if ((index + 1) % 3 === 0 && index !== 0) {
                      colSpan = "4";
                    }

                    return (
                      <div
                        className={`col-span-${colSpan} touchbox-bg-color p-4 rounded-lg `}
                      >
                        <Link
                          to={action.path}
                          state={{ prevPath: location.pathname }}
                        >
                          <div className="justify-center items-center flex mb-1">
                            <img
                              src={action.icon}
                              style={{ width: 37, height: 30 }}
                            />
                          </div>
                          <div className="text-xs text-black  text-center text-white">
                            {get(action, "text")}
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="mt-2">
                <div className="flex justify-center text-lg mb-1  text-white px-6">
                  {/* 奖励  */}
                  {/* <Divider> */}
                  <span className="text-white">
                    {t("rewards", sourceKey.pairing)}
                  </span>
                  {/* </Divider> */}
                </div>
                <div className="mx-4 ">
                  <RewardListing />
                </div>
                <div className="grid grid-cols-6 gap-2 px-6 pt-2">
                  {map(rewards2, (action, index) => {
                    let colSpan = "3";

                    return (
                      <div
                        className={`col-span-3 touchbox-bg-color p-4 rounded-lg `}
                      >
                        <Link
                          to={action.path}
                          state={{ prevPath: location.pathname }}
                        >
                          <div className="justify-center items-center flex mb-1">
                            <img
                              src={action.icon}
                              style={{ width: 30, height: 30 }}
                            />
                          </div>
                          <div className="text-xs text-black  text-center text-white">
                            {get(action, "text")}
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <PairingLoginModal
              visible={loginVisible}
              user={user}
              onSuccess={() => {
                props.pairingLogin(true);
              }}
            />
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateActiveId,
  pairingLogin,
};
export default connect(mapStateToProps, mapDispatchToProps)(RewardExplore);
