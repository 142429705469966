import * as React from "react";
import SharedHeader from "../../components/general/components/SharedHeader";
import RewardExplorePage from "../../components/rewardExplore/pages/RewardExplorePage";

// markup
const Login = () => {
  return (
    <React.Fragment>
      <SharedHeader />
      <RewardExplorePage />
    </React.Fragment>
  );
};

export default Login;
